import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  Content,
  ImageCollection,
  InternalLink,
  LocationsSorted,
} from "@bluefin/components";
import { Image, Grid, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage, getPageGalleryImages } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <div className={"hero-container"}>
              <HeroElement
                header={fishermanBusiness.name}
                tagline={
                  <div>
                    <h3>
                      (not affiliated with third party delivery companies)
                    </h3>
                  </div>
                }
                images={getPageHeroImage({
                  options: fishermanBusinessWebsitePage.components,
                })}
                ctas={[fishermanBusiness.ctas.orderOnline]}
                ctaInverted={true}
                ctaColor={"white"}
                containerColor={"primary"}
                textColor={"white"}
                showMultiple={true}
                overlay={true}
                fullWidth={true}
                parallax={false}
                containerAs={"none"}
                height={500}
                gutter={false}
              />
              <div className={"img-container"}>
                <Image
                  size={"small"}
                  centered={true}
                  src={
                    "https://fisherman.gumlet.io/public/fetasgreekrestaurant/1c0b6c6d-4f05-48e8-9534-4b242c85c094.png"
                  }
                />
              </div>
            </div>
          }
          subfooter={false}
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <Content>
                <Content.Markup>
                  {fishermanBusiness.aboutMarkdown}
                </Content.Markup>
              </Content>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <ImageCollection
                images={getPageGalleryImages({
                  options: fishermanBusinessWebsitePage.components,
                })}
                as={"gallery"}
                centered={true}
                cutUnevenRows={false}
                disable={"none"}
                header={"Photos"}
                numberOfRowDisplayItems={3}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container salads-dressing"}
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={16}>
              <Content container={true}>
                <Content.Markup isMarkdown={false}>
                  <Grid
                    stackable={true}
                    columns={"2"}
                    vertialAlign={"middle"}
                    textAlign={"center"}
                  >
                    <Grid.Column>
                      <h3>
                        Bring the Taste of Feta's Greek Salad Dressing to Your
                        Home! Have it delivered right to you...
                      </h3>
                    </Grid.Column>
                    <Grid.Column>
                      <Image
                        src={
                          "https://fisherman.gumlet.io/public/fetasgreekrestaurant/dressing.jpg"
                        }
                        centered={true}
                        size={"medium"}
                      />
                    </Grid.Column>
                  </Grid>
                </Content.Markup>
              </Content>
            </Grid.Column>
            <Grid.Column width={16} textAlign={"center"} centered={true}>
              <Button
                content={"Read More"}
                className={"readmore"}
                size={"large"}
                as={InternalLink}
                to={"/salad-dressing"}
              />
            </Grid.Column>
            <Grid.Column width={16}>
              <LocationsSorted
                locations={fishermanBusiness.locations.map((location) => {
                  const locationNameToOrderLink = {
                    "Miracle Hills Square":
                      "https://direct.chownow.com/order/35467/locations/53667",
                    Midtown:
                      "https://direct.chownow.com/order/35467/locations/53668",
                  };
                  return {
                    ...location,
                    actions: [
                      {
                        url: locationNameToOrderLink[location.name],
                        label: "Order Online",
                      },
                    ],
                  };
                })}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        orderOnline {
          text
          link
          internal
          className
        }
      }
      aboutMarkdown
      locations {
        name
        timezone
        phoneNumber
        email
        hours {
          open
          close
          day
          label
        }
        street
        city
        state
        zipCode
        country
        latitude
        longitude
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Home" }) {
      title
      description
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
